import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';
import myNft from './utils/MyNft.json';
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';
import { Link, useSearchParams } from "react-router-dom";

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";

import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";

import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";

import { Spinner } from "./Spinner";

// MEGA CONFIGS!!!!
// ##################################################################################################

    // POP IN THE MERKLE API URI:   NOTE, I HAVE MULTIPLE ACTIVE ONES NOW, SO BE CAREFUL!
    let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api2.netlify.app/.netlify/functions/express/api/"; 

    // MAIN NORMAL STUFF TO DEFINE:
    let CONFIGS_DROPNAME = 'Chef Boi R Doge: Mixed Breeds (CBRDM)';  // used in ALTs and so forth
    let CONFIGS_MINT_PRICE_CALC = 0.1; // used for calculating
    let CONFIGS_PROMO_PRICE_CALC = 0.09; // used for calculating
    let CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;
    const CONFIGS_TOTALSUPPLY_CALC = 15000; // used for calculating -- I think we may need this to calculate SOLD OUT
    let CONFIGS_TOTALSUPPLY_DISPLAY = '15,000'; // used for display purposes only...

    //todo: go get this value automatically...
    let CONFIGS_NFTS_RESERVED = 0; // I think we may need this to calculate SOLD OUT
    let reserve_supply;    

    let CONFIGS_WALLET_LIMIT = 200; // High limit public, 10 presale..
    let CONFIGS_TRANSACTION_LIMIT = 10; // testing only! Actual settings tbd.

    const CONFIGS_CONTRACT_ADDRESS = "0x6c789Add7623C615a22b5Bb9774d31aA37B0fA2a";
    let CONFIGS_CHAIN_ID = 1; // Chain ID:  1==MAINNET, 5==GOERLI
    let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
    let CONFIGS_NETWORK = ''; //SB: blank (mainnet) or 'goerli.' (w/ period) -- used for link building only
    const CONFIGS_JSON_METADATA_BASEURI = 'ipfs://QmVPLq3wPzs1K429DLJy8iJuSdRWoVDiTb4TA8JFi7fNGb/';
    let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
    let CONFIGS_NFT_TITLE_SINGULAR = 'MIXED BREED';
    let CONFIGS_NFT_TITLE_PLURAL = 'MIXED BREEDS';
    let CONFIGS_URLS_TWITTER = 'https://twitter.com/ChefBoiRDoge';
    let CONFIGS_URLS_INSTAGRAM = 'https://www.instagram.com/chefboirdoge/';
    let CONFIGS_URLS_TIKTOK = 'https://www.tiktok.com/@highheatrichie?lang=en';
    let CONFIGS_URLS_DISCORD = 'https://discord.com/invite/R8wYmF6knN';
    let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/cbrdmixedbreeds';
    let CONFIGS_URLS_PROJECTWEBSITE = 'https://chefboirdoge.com/';
    let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;
    let CONFIGS_SHOW_COLLECTION = 0; // make 1 to show, 0 to NOT show NFT collection at bottom.

    // VARIOUS MESSAGES / STRINGS:
    let CONFIGS_ALLOW_LIST_MESSAGE = 'Sorry, this is an allowlist-only minting period and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.';

    let CONFIGS_BEGIN_MINT_ALERT = 'Minting now... please click Okay and wait while we write your mint to the blockchain. Another alert will popup shortly and let you know that the mint is complete.';

    let CONFIGS_MINT_SUCCESS_ALERT = 'You have succesfully minted! Your NFT(s) should appear in your wallet and on OpenSea shortly.';

    // VARIOUS ERRORS FROM SOLIDITY:
    // Fill this object with some terms that would be found from our solidity contract's errors,
    // and then we can write custom responses for the alerts (but keep them all up here):
    let SOLIDITY_ERROR_LIST = {
        1: {
            'error': '[error text snippet from wallet error]',
            'response': '[alert response]'
        },
        2: {
            'error': 'exceed the wallet limit',
            'response': 'Your transaction would exceed the wallet limit.'
        },
        3: {
            'error': 'exceed the max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        4: {
            'error': 'exceed max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        5: {
            'error': 'Sale is not active',
            'response': 'The sale has been disabled on the smart contract.'
        },
        6: {
            'error': 'Not enough ether sent',
            'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
        },
        7: {
            'error': 'User denied transaction',
            'response': 'The user has denied the current transaction.'
        },
        8: {
            'error': 'already claimed',
            'response': 'It looks like you have already claimed your allowlist NFTs.'
        },
        9: {
            'error': 'insufficient funds',
            'response': 'Insufficient funds. Please add enough ETH to your wallet for this NFT purchase + gas.'
        },
        10: {
            'error': 'are not allowlisted',
            'response': 'Sorry, you are not on the presale list. Please contact the team if you believe this to be an error.'
        }, 
        11: {
            'error': 'exceed the transaction',
            'response': 'Sorry, you cannot purchase that many NFTs in a single transaction during this mint.'
        }
    }

    let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';

// ##################################################################################################
function floatify(number){
    return parseFloat((number).toFixed(10));
 }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return ( <Web3ReactProvider getLibrary = { getLibrary }><App/></Web3ReactProvider>) };

const App = () => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
        console.log('running')
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    const [mintCount, setMintCount] = useState(0);
    const [value, setValue] = useState(1);
    const [myNFTs, setMyNFTs] = useState([]);
    const [trackMintCount, setTrackMintCount] = useState(0);
    const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
    const [isSold, setIsSold] = useState(false);
    const [isLibrary, setIsLibrary] = useState(false);
    const [mintPrice, setMintPrice] = useState(CONFIGS_CURRENT_PRICE); 
    const [reserveSupply, setReserveSupply] = useState(CONFIGS_NFTS_RESERVED); 
    
    let tokenId;
    let signer;
    let currentTotalSupply;

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log("Mint amount = " + value);
    };

    const setupLibrary = async() => {

        try {

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup Library");

        } catch (error) {
            console.log(error);
        }
    }

    const setupEventListener = async() => {

        try {
            const wallet = library;
            signer = wallet.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            // event listener
            connectedContract.on("Mint", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log(from, tokenId.toNumber());
            });

            if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setMintCount(CONFIGS_TOTALSUPPLY_CALC);
                setIsSold(true);
            }
            console.log('mintCount: ' + mintCount);

            console.log('EventListener...'+searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('EventListener Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('EventListener Affiliate activated? : ' + isActive);    

                if (theCommission && isActive) {
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                }

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup event listener!");

        } catch (error) {
            console.log(error);
        }
    }

    const askContractToMintNft = async() => {
        
        console.log('ChainID is: '+chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            let isAffiliate = false;
            console.log(searchParams);
            console.log(searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

            // Cast to lowercase because we don't want case to be a problem...
            // Affiliates can share refs like Jim, jim, JIM, etc. and it'll still
            // work because we are enforcing all lowercase on the contract side.
            affiliateRef = affiliateRef.toLowerCase();
            console.log('Affiliate ref is:'+affiliateRef);

            if(affiliateRef){

                // ok looks like an affiliate sale.
                isAffiliate = true;
              
                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('Affiliate activated? : ' + isActive);

                // If eitehr of those tests fail, we will not pass this along to the mint function
                // as an affiliate sale because it would throw a needless error. Instead, we will
                // pass it as just a non-commission sale. Kthx.
                if (theCommission==0 || isActive==false) { 
                    affiliateRef=''; isAffiliate = false; 
                    console.log('Mint passed to contract, but note that the user has likely come to a URL of an invalid affiliate because no fee structure was estabished on the smart contract and/or the affiliate was not activated on the contract.');
                }

            }

            console.log("Public Minting: Popping wallet open now.")
            
            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Total Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.mint(value, isAffiliate, affiliateRef, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });

            console.log("Public Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Mint tx: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {
            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const allowlistMint = async() => {
        console.log(chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            const walletAdd = await signer.getAddress();
            console.log(String(walletAdd));
            const requestOptions = {
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": null,
                "method": "GET",
                "mode": "cors",
                "credentials": "omit"
            }

            let res = await fetch(CONFIGS_MERKLE_API + String(walletAdd), requestOptions);
            console.log(res);

            let merkleObject = await res.json();
            console.log(merkleObject);

            if (!merkleObject.valid) {
                alert(CONFIGS_ALLOW_LIST_MESSAGE);
                return;
            }

            console.log("Allowlist Mint: Popping wallet now to pay gas.");

            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Allowlist Ttl Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.allowlistMint(merkleObject.proof, value, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });
            
            console.log("Allowlist Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {

            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const getTotalNFTsMintedSoFar = async() => {

        try {

            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("Getting Mint Count")
            let mint_count = await connectedContract.totalSupply();
            console.log(ethers.utils.formatUnits(mint_count, 0));
            setMintCount(ethers.utils.formatUnits(mint_count, 0));
            console.log(`Set mint count is ${mint_count}`);

            console.log("Getting Reserved Supply")
            let reserve_supply = await connectedContract.reservedNFTs();
            setReserveSupply(ethers.utils.formatUnits(reserve_supply, 0));
            console.log(`Reserve supply is ${reserve_supply}`);


            console.log('Getting Minted NFTs');
            const userBalance = await connectedContract.balanceOf(account);
            console.log(userBalance);

            if (mint_count >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setIsSold(true);
            }

        } catch (error) {

            console.log(error)

        }
    }

    const getTotalOwnerNFTs = async() => {

        if (CONFIGS_SHOW_COLLECTION == 1) {

            try {
                console.log("Getting total owner nfts");
                const provider = library;
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                const userBalance = await connectedContract.balanceOf(account);
                console.log(userBalance);
                let arrNFT = []
                let data = null;

                for (let i = 0; i < userBalance; i++) {
                    console.log("entering collection for time: ", i);
                    tokenId = await connectedContract.tokenOfOwnerByIndex(account, i);
                    var openSeaLink = "https://" + CONFIGS_OPENSEA_URL + "/assets/" + CONFIGS_CONTRACT_ADDRESS + "/" + tokenId;

                    let metadataLink = CONFIGS_JSON_METADATA_BASEURI + tokenId;

                    await fetch(metadataLink)
                        .then(
                            function(response) {

                                if (response.status !== 200) {
                                    console.log('Error code: ' + response.status);
                                    return;
                                }

                                response.json().then(function(data) {

                                    //console.log(data);

                                    //console.log('CurrentID: ' + tokenId);
                                    var jsonRes = JSON.parse(JSON.stringify(data));
                                    var imageSrc = jsonRes.image;
                                    imageSrc = imageSrc.replace("ipfs://", "https://ipfs.io/ipfs/");
                                    data.image = imageSrc;
                                    data.openSeaLink = openSeaLink;
                                    //console.log('img src=' + imageSrc);
                                    arrNFT.push(data);
                                    setMyNFTs([...arrNFT]);
                                    let collectionImage = '<div class="collectionItem"><a target="blank" href="' + openSeaLink + '"><img class="collectionImage" src="' + imageSrc + '" /><div class="collectionTokenTitle">' + CONFIGS_NFT_TITLE_SINGULAR + ' #' + tokenId + '</div></a></div>';

                                });
                            }
                        )
                        .catch(function(err) {
                            console.log('Fetch Error :-S', err);
                        });

                }

                console.log(myNFTs);
                console.log(arrNFT);

            } catch (error) {
                console.log(error)
            }

        }

    }

    const onDisconnect = async() => {
        console.log("Killing the wallet connection", library);
        setIsLibrary(false);
        // disconnect wallet
        deactivate();
        console.log('disconnect acct: '+account);
        console.log('disconnect lib: '+library);
        console.log('disconnect conn: '+connector);
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);
        console.log('disconnect mint price: '+CONFIGS_CURRENT_PRICE);
        
    }

    useEffect(() => {
        setupLibrary();
    });

    useEffect(() => {
        if (library) { setupEventListener() };
    }, [isLibrary]);

    useEffect(() => {
        console.log(isLibrary);
        console.log(trackAnyMintCount);
        if (library) { getTotalNFTsMintedSoFar() }
    }, [isLibrary, trackAnyMintCount]);

    useEffect(() => {
        if (library) { getTotalOwnerNFTs() }
    }, [isLibrary, trackMintCount]);



    return (

        <div className="App">

            <div className="header" >

                <div className="headerLeft">

                    <a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="gen1logo.png" className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a> 

                    {/* <a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="droplogo.png" className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a> */}

                </div>

                <div className="headerRight">

                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }><img src="logo-twitter.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} title={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_INSTAGRAM }><img src="logo-instagram.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} title={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TIKTOK }><img src="logo-tiktok.png" alt={'Catch ' + CONFIGS_DROPNAME + ' on TikTok'} title={'Catch ' + CONFIGS_DROPNAME + ' on TikTok'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_DISCORD }><img src="logo-discord.png" alt={'Join the ' + CONFIGS_DROPNAME + ' Discord'} title={'Join the ' + CONFIGS_DROPNAME + ' Discord'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }><img src="logo-opensea.png" alt={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} title={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} /></a> 
                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }><img src="logo-etherscan.png" alt={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} title={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} /></a> 
                </div>

            </div>

            <div className="clear"> </div>

            <div className="mintBoxWrapper" >

                <div className="mintBoxLeft" >

                    <div className="allButMobile">
                        <img src="featured.gif" alt={CONFIGS_DROPNAME} />
                    </div>

                </div>

                <div className= "mintBoxRight" >

                    <div className="mintBoxRightWelcome">

                        <img className= "mobileOnly" src="featured.gif" alt="" />

                        {/* <p className="supplyInfo">{CONFIGS_TOTALSUPPLY_DISPLAY} {CONFIGS_NFT_TITLE_PLURAL} <br/>@ { mintPrice } ETH</p> */}
                        
                        <p className="supplyInfo">C.B.R.D. {CONFIGS_NFT_TITLE_PLURAL} (aka "GEN2")</p>

                        <ul>
                            <li>For each Gen1 Mutt you own, you may free claim a Gen2 Mixed Breed. To do that, go to our <a href="https://claim.chefboirdoge.com/">claim page</a>.</li>
                            <li>To purchase a Gen1 Mutt (and then claim your Gen2 after), go to our <a href="https://mint.chefboirdoge.com/">Gen1 Mutt mint page</a>.</li>
                            <li>To purchase a Gen2 Mixed Breed NFT directly, use the minting app below.</li>
                        </ul>

                    </div>

                    <div id="DAppArea" >

                        {
                            (active === false) ? ( 

                                <div id="prepare">

                                    {/* <p><br/><b> Note: </b> If you do not have a wallet installed, you will not see a "Connect Wallet" button.<br/></p> */}

                                    <button onClick = { openModal } className="btn-primary pageButton" id="connectButton" type="button" >Connect Wallet</button> 

                                    <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                        <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                                                                
                                        {
                                            Object.keys(connectorsByName).map(name => {
                                                const currentConnector = connectorsByName[name];
                                                const activating = currentConnector === activatingConnector;
                                                const connected = currentConnector === connector;

                                                return (
                                                    <button className="providerChoices" key={ name } onClick = { () => {
                                                            setActivatingConnector(currentConnector);
                                                            activate(connectorsByName[name]);
                                                            closeModal(); }}>
                                                        <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }> { activating && ( <Spinner color={"black"} style={{height:"25%", marginLeft: "-1rem" } } /> ) }
                                                        </div> 
                                                        <img className='logo-wallet' src={ connectorImagesByName[name] } alt="" /> 
                                                        <span className="walletName" > { name } </span>
                                                    </button>
                                                );

                                            })
                                        }

                                    </Modal>

                                </div>

                            ):(

                                (isSold === false) ? ( 

                                    <div>
                                        
                                        { /*
                                        <p className="sub-text" > Total Gen2 NFTs:{ ' ' } <span className="yellow">15,000</span></p> 

                                        <p className="sub-text" > Gen1 Holder Claims Available:{ ' ' }<span className="yellow">{ reserveSupply }</span> { ' ' } / { ' ' } 10,000</p> 
                                        
                                        <p className="sub-text" > Gen2 Mints Available:{ ' ' }<span id="currentMintCount" className="yellow">{ mintCount }</span> { ' ' } / { ' ' } 5,000</p>
                                        */ }

                                        <p className="sub-text"> Mint Price @ <span className="yellow">{ mintPrice }</span> ETH</p>

                                        <p className="sub-text" style = { { marginBottom: "6px" } }>Select Quantity to Mint Now:</p>

                                        <div className="theSlider">
                                            <input id="sliderVal"  className="slider"  onChange = { handleChange }  max={ CONFIGS_TRANSACTION_LIMIT } min="1" type="range"  value = { value } /> 
                                        </div>                                        

                                        { /* PRESALE MINT BUTTON  
                                        <button onClick = { allowlistMint } className="btn-custom btn-primary pageButton" type="button" id="theMintButton" >PRESALE Mint { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL
                                        } </button>*/ }
                                        

                                        { /* PUBLIC SALE MINT BUTTON --*/ }
                                        <button onClick={ askContractToMintNft } className="btn-custom btn-primary pageButton" type="button" id="theMintButton">Mint { value } { ( value < 2 ) ? CONFIGS_NFT_TITLE_SINGULAR : CONFIGS_NFT_TITLE_PLURAL }
                                        </button> 
                                        

                                        <div id="totalPrice" >@ <b>{ (CONFIGS_CURRENT_PRICE * value).toFixed(4) }</b> ETH + gas </div>

                                        <div id="connected">

                                            <button onClick = { onDisconnect } className="btn btn-primary pageButton" id="disconnectButton" type="button">Disconnect <span class='smaller fixcaps'> 0x{ String(account).slice(2, 5) + "..." + String(account).slice(-4) }</span></button>

                                            <p className="buttonMessage">After minting, head to <a target="_blank" href={ 'http://'+ CONFIGS_OPENSEA_URL + '/' + account }>your wallet on OpenSea</a> to view.</p>

                                        </div> 

                                        <p className="sub-text">Note: Five of the Super Rares are Golden Tickets, which will have special utility later. If you mint one of these super rare tickets, we will airdrop you a regular Mixed Breed to have as well!</p>


                                    </div>

                                ) : (

                                    <div id="totalPrice">COLLECTION IS SOLD OUT<br /><a href={CONFIGS_URLS_OPENSEA_COLLECTION}>Have a look on OpenSea</a> to grab a rare on the secondary market.</div>

                                )

                            )
                        }

                        <p><span className="soldFont" id="amountSold"> </span></p>

                    </div>

                </div>

            </div>

            <div className="clear" > </div>

            {

                (CONFIGS_SHOW_COLLECTION == 1) ? (

                    <div>

                        <div className="collectionWrapper" >

                            <h2>Your NFTs From This Collection</h2>

                            <p style = { { maxWidth: "80%", marginLeft: "auto", marginRight: "auto", fontSize: "125%", padding: "20px" } } > After purchasing NFTs from this collection, your NFTs will be written to the blockchain and will then appear below.They will also link to OpenSea, where you will be able to view NFT properties(once revealed), view the collection, and buy / sell / trade NFTs. </p>

                            <div className="collectionRow" id="renderCollection">

                                {
                                    myNFTs.map((myNFT) =>
                                        <div key={ myNFT.name } className="collectionItem">
                                            <a target="_blank" rel="noreferrer" href={ myNFT.openSeaLink }>
                                                <img className="collectionImage"  src={ myNFT.image }  alt="" />
                                                <div className="collectionTokenTitle">
                                                    <p>{ myNFT.name }</p>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }

                            </div>

                        </div>

                        <div className="clear" ></div> 

                    </div>

                ) : ( 
                
                    <div className="noCollectionSpacer"> </div>

                )

            }

        </div>

    );

};
